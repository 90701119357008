import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts"

const Unscripted = () => (
  <Layout title="Unscripted">
    <div className="container is-widescreen">
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <h1 className="title has-text-primary has-text-centered">
            Unscripted
          </h1>
          <div className="content">
            <p>
              When you think about the team behind a&nbsp;
              <a target="_blank" rel="noreferrer"
                 href="https://trueinfluence.com/upgrade-your-content-marketing-upgrade-your-brand-image/">
                solid content marketing strategy
              </a>
              , what types of individuals come to mind? Writers? Of course. Graphic designers? Sure. Animators and video
              producers? You bet. Data scientists? Probably not… as yet anyway.<br/>
              But that’s about to change with data-driven branded content. As big data plays an increasingly greater
              role in content development, the traditionally right-brain realm of branded content and formats are
              getting a healthy dose of left-brain data crunching.
              <br/><br/>
              Welcome to the world of data driven scripts.
              <br/><br/>
              We employ social listening to detect the most interesting topics, and identify the most suitable writers
              and creators for YOUR content. We listen to your target audience to reach it in the most effective way.
              <br/><br/>
              That is how we can develop and produce impressive formats, as well as videos, docuseries, and longer
              formats, with the coolest artists, sports athletes, and celebrities, for any type of brand across the
              world.
              <br/><br/>
              Would you like to know more? Send us an email and we will contact you to discuss your next topic…
            </p>
          </div>
          <div className="has-text-centered">
            <Link to="/contact-us" className="button is-primary is-large is-rounded action-button ">
              <span className="icon is-medium">
                <i className="far fa-comment"/>
              </span>
              <span>Contact us</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Unscripted